import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BloqueContacto from "../components/bloque-contacto"
import imgSomos from '../images/dibus/somos.svg'
import imgDonde from '../images/dibus/donde-estamos.svg'
import imgExperiencia from '../images/dibus/experiencia.svg'

import imgPorque from '../images/dibus/imgPorque.svg'

import imgPorque1 from '../images/dibus/imgPorque1.svg'
import imgPorque2 from '../images/dibus/imgPorque2.svg'
import imgPorque3 from '../images/dibus/imgPorque3.svg'
import imgPorque4 from '../images/dibus/imgPorque4.svg'
import imgPorque5 from '../images/dibus/imgPorque5.svg'
import imgPorque6 from '../images/dibus/imgPorque6.svg'



const QuienesPage = () => (
  <Layout className="page-quienes">
    <SEO title="Quienes somos" />
    
    <section className="bloque bloque-main bloque-quienes-main">
		<div className="container">
		 	<h1 className="main-title">Quienes Somos</h1>
		</div>
    </section>

	<section className="container bloque bloque-first">
		<div className="row">
			<div className="col-sm-6 col-md-4">
				<article className="col-somos">
					<figure>
						<img className="quienes-img" src={imgSomos} alt="" />
					</figure>
					<h4>Somos un estudio</h4>
					<p>Cocowat es un estudio de desarrollo web y que está formado esencialmente por Anna Ferry y Jara Paredes, 
					aunque contamos con colaboradores externos que nos echan un cable en nuestros picos de trabajo.</p>
				</article>
			</div>
			<div className="col-sm-6 col-md-4 col-estamos">
				<article className="col-estamos">
					<figure>
						<img className="quienes-img" src={imgDonde} alt="" />
					</figure>			
					<h4>Dónde estamos</h4>
					<p>Desde Barcelona realizamos trabajos a toda España, y también a otras partes del mundo. A día de hoy, Internet no es un problema para ofrecer nuestros servicios, 
					todos ellos están pensados para realizarlos online.</p>
				</article>
			</div>
			<div className="col-sm-6 col-md-4 col-experiencia">
				<article className="col-experiencia">
					<figure>
						<img className="quienes-img" src={imgExperiencia} alt="" />
					</figure>			
					<h4>Nuestra experiencia</h4>
					<p>Disponemos más de 15 años en el sector y más de 200 clientes contentos, por eso todos nuestros proyectos tienen una garantía y soporte por nuestros 
					profesionales, queremos dar total confianza de todos nuestros servicios.</p>
				</article>
			</div>								
		</div>
	</section>

	<section className="bloque bloque-porque bg-dark-blue">
		<div className="container">
			<h2 className="title title-boxed">¿Por qué elegirnos?</h2>

			<div className="row">
				<div className="col-sm-6 bloque-texto">
					<h4>Profesionalidad, comunicación y calidad</h4>
					<p>Porque somos un estudio de desarrollo web compuesto por profesionales expertos que desde el año 2005 trabajan con varias empresas generándoles ventas online. 
					Estamos disponibles mediante diferentes medios para poder tener un contacto directo con todos nuestros clientes y poder solventar sus problemas.  </p>
				</div>
				<div className="col-sm-6">
					<figure>
						<img src={imgPorque} alt="" />
					</figure>			
				</div>
			</div>			
		</div>
	</section>


	<section className="bloque bloque-porque-6">
		<div className="container">
			<div className="row">
				<article className="col-sm-6 col-md-4">
					<figure>
						<img className="porques-img" src={imgPorque1} alt="" />
					</figure>
					<h4>Mejorar tu negocio</h4>
					<p>Nuestras soluciones están orientadas a mejorar y ahorrar trabajo a nuestros clientes. Además te asesoramos para que la herramienta que diseñemos te ayude a gestionar tu empresa. </p>
				</article>		
				<article className="col-sm-6 col-md-4">
					<figure>
						<img className="porques-img" src={imgPorque2} alt="" />
					</figure>
					<h4>Especialistas en UX/UI</h4>
					<p>Somos especialistas en diseño UX/UI. No solo hacemos que las webs sean bonitas sino que estén adaptadas a las necesidades del usuario, sean usables y accesibles. </p>
				</article>	
				<article className="col-sm-6 col-md-4">
					<figure>
						<img className="porques-img" src={imgPorque3} alt="" />
					</figure>
					<h4>Aprendizaje constante</h4>
					<p>Venimos de un sector, que tiene que estar en continuo aprendizaje, la formación es uno de nuestros principales valores, para aprender nuevas tecnologías y poder ofrecer mejores soluciones a nuestros clientes.</p>
				</article>	
				<article className="col-sm-6 col-md-4">
					<figure>
						<img className="porques-img" src={imgPorque4} alt="" />
					</figure>
					<h4>Aplicaciones móviles</h4>
					<p>También hacemos aplicaciones móviles de todo tipo: nativas o híbridas. Nos adaptamos al dispositivo que mejor vaya para tu negocio. </p>
				</article>	
				<article className="col-sm-6 col-md-4">
					<figure>
						<img className="porques-img" src={imgPorque5} alt="" />
					</figure>
					<h4>Diseños sin plantillas</h4>
					<p>No utilizamos plantillas, cada diseño es original y solo para ti. Destacarás sobre tu competencia ya que no encontrarás una web similar a la tuya. Además, cuidamos hasta el último detalle.</p>
				</article>	
				<article className="col-sm-6 col-md-4">
					<figure>
						<img className="porques-img" src={imgPorque6} alt="" />
					</figure>
					<h4>Disfrutamos con todo lo que hacemos</h4>
					<p>Nos gusta desarrollar aplicaciones web y se nos da muy bien, vamos, que lo disfrutamos, así que las hacemos con cariño desde el principio hasta el final.</p>
				</article>																					
			</div>
		</div>
	</section>

	<section className="container bloque bloque-bolitas">
		<div className="box">
			<h4>Unos cuantos motivos más...</h4>

			<div className="row">
				<div className="col-sm-6">
					<span className="bola bola-pink"></span>
					<p>Ponemos pasión en todos nuestros proyectos</p>
				</div>	
				<div className="col-sm-6">
					<span className="bola bola-yellow"></span>
					<p>Pensamos en los clientes</p>
				</div>	
				<div className="col-sm-6">
					<span className="bola bola-blue"></span>
					<p>Desarrollamos grandes proyectos. No hay proyecto pequeño</p>
				</div>	
				<div className="col-sm-6">
					<span className="bola bola-dark-blue"></span>
					<p>Cercanía de todo nuestro equipo de profesionales</p>
				</div>																															
			</div>

		</div>
	</section>


 	<BloqueContacto />


  </Layout>
)

export default QuienesPage